<template>
    <div class="share-wrap relative">
        <div class="share-link absolute padding-top-bottom-10 left-50p full-widht flex row wrap y-center x-center transition-0-35" :class="{'share-link_on':isShow}">
        <ul class="bullets-none">
            <li>
                <a :href="`https://api.whatsapp.com/send?text=Te%20comparto%20zidarspace:%20${dbSettings.siteUrl}`" clase="share-btn share-by-whatsapp" target="_blank"> 
                    <span 
                    class="iconify gray-dark-light-3"
                    data-icon="mdi:whatsapp"
                    data-width="20"
                    data-height="20"
                    ></span>
                </a>
            </li>
             <li class="close-share">
                <button @click="onShare" class="button button_icon blue">
                    <span class="material-icons-outlined" >close</span>
                </button>
            </li>
            <li>
                <a href="mailto:?subject=ZidarSpace&amp;body=Te%20comparto%20:%20https://www.zidarspace.web.app">
                   <span class="material-icons-outlined">
                        mail
                    </span>
                 </a>
            </li>
        </ul>
        </div>
        <button class="button button_icon" @click="onShare" >
            <span class="material-icons-outlined">
                share
            </span>
         </button>
    </div>

</template>
<script>
import { reactive, toRefs} from "vue"
import { useSettingsStore } from "@/stores/settingsStore"
export default {
    setup() {
        const dbSettings = useSettingsStore();
        const dataShare = reactive({
            isShow:false
        });
        function onShare(){
            dataShare.isShow = !dataShare.isShow;
        }
        return {
            ...toRefs(dataShare),
            dbSettings,
            onShare
        };
    },
}
</script>