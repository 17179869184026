<template>
    <div class="share-box full-width fixed bottom-0 left-0 zindex-900">
        <div class="container container_md full-height flex row wrap items-center space-between">
            <WspCall :onlyIcon="true" />
            <router-link to="/">
                <img class="logo-img-2" src="@/assets/zidar-iso.svg" width="25" />
            </router-link>
            <Share />
        </div>
    </div>
</template>
<script>
import Share from '@/components/share-tools/Share'
import WspCall from '@/components/share-tools/WspCall'
export default {
    setup() {
        return{

        }
    },
    components: {
        Share,
        WspCall
    }
}
</script>