import { db } from "@/firebase.js"
import { defineStore } from 'pinia'
export const useDbStore = defineStore({
    id: 'dbStore',
    state(){
        return {
            data:[],

            allHomeData:[],
            allServicesData:[],
            allObrasData:[],

            homeIntro:[],
            homeServices:[],
            homeServices2:[],

            obrasIntro:[],
            gallery:[],

            
        }
    },
    actions:{
        //all home data
        async setAllHome(){   
            try {
                await db.collection('home').onSnapshot((snapshot) => {
                    let dataArray = [];
                    snapshot.forEach((doc) => {
                        dataArray.push(doc.data());  
                    });
                    return this.allHomeData = dataArray; 
                })
            }
            finally {
                this.preload = false; 
            }
        },

        //all services data
        async setAllServices(){   
            try {
                await db.collection('services').onSnapshot((snapshot) => {
                    let dataArray = [];
                    snapshot.forEach((doc) => {
                        dataArray.push(doc.data().data);  
                    });
                    return this.allServicesData = dataArray[0]; 
                })
            }
            finally {
                this.preload = false; 
            }
        },

        //all obras data
        async setAllObras(){   
            try {
                await db.collection('obras').onSnapshot((snapshot) => {
                    let dataArray = [];
                    snapshot.forEach((doc) => {
                        dataArray.push(doc.data());  
                    });
                    return this.allObrasData = dataArray; 
                })
            }
            finally {
                this.preload = false; 
            }
        },

        //by documents
        async setHomeIntro(){
            let docRef = await db.collection('home').doc('intro');
            docRef.get().then((doc) => {
                return doc.exists ?  this.homeIntro = doc.data().data :  console.log("No such document!");
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        },

        async setHomeServices(){
            let docRef = await db.collection('home').doc('services');
            docRef.get().then((doc) => {
                return doc.exists ? this.homeServices =  doc.data().data : console.log("No such document!");
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        },

        async setHomeServices2(){
            let docRef = await db.collection('home').doc('services-projects-construction');
            docRef.get().then((doc) => {
                return doc.exists ? this.homeServices2 =  doc.data().data : console.log("No such document!");
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        },

        async setObrasIntro(){
            let docRef = await db.collection('obras').doc('obras-first-section-data');
            docRef.get().then((doc) => {
                return doc.exists ? this.obrasIntro =  doc.data().data : console.log("No such document!");
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        },

        async setGallery(){
            let docRef = await db.collection('obras').doc('obras-gallery');
            docRef.get().then((doc) => {
                return doc.exists ? this.gallery =  doc.data().data : console.log("No such document!");
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }


    },
  
    getters:{

    }
  
});


