<template>
    <a 
    class="white wsp-call underline-none margin-top-bottom-5 weight-700" 
    :href="`https://wa.me/5980${wspNumber}/?text=${wspText}`" 
    target="_blank" title="Llámanos por Whatsapp" >
        <template v-if="!onlyIcon">
            0{{wspNumber}}
        </template>
        <template v-else>
            <span 
                class="iconify gray-dark-light-2"
                data-icon="mdi:whatsapp"
                data-width="24"
                data-height="24"
            ></span>
        </template>
    </a>
</template>
<script>
export default {
    props:{
        wspNumber: {
            type:String,
            default:'93349943'
        },
        wspText: {
            type: String,
            default:'Mensaje%20desde%20Zidar%20'
        },
        onlyIcon: {
            type: Boolean,
            default:false
        }
    },
    setup(props) {
        return {
          props
        }
    },
}
</script>