import { defineStore } from 'pinia'

export const useSettingsStore = defineStore({
  id: 'settings',
  state() {
    return { 
        wspIntroText:'',
        siteUrl:'',
        headerFixed:null,

    }
  },
  actions:{
    setWspIntroText(n){
        this.wspIntroText = n;
    },
    setSiteUrl(n){
      this.siteUrl = n;
    },
    setHeaderFixed(n){
        this.headerFixed = n;
    }
  }
})