import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import 'firebase/app-check'; 

const firebaseConfig = {
  apiKey: "AIzaSyAFH8fTMKavdQWJXHqZN23FNsqvcU-o0zg",
  authDomain: "zidarbkd.firebaseapp.com",
  projectId: "zidarbkd",
  storageBucket: "zidarbkd.appspot.com",
  messagingSenderId: "437220837738",
  appId: "1:437220837738:web:4b5a8d3eaa12feabf9fd13"
};

firebase.initializeApp(firebaseConfig);

export const fb = firebase;
export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const appCheck = firebase.appCheck();
appCheck.activate(
  '6LfGLn4dAAAAAF8L1T2zH_gjDRbTD072PCe7zDcY',
  true);