<template>
  <BaseLayout />
  <ShareBox />
</template>
<script>
import BaseLayout from "@/components/layout/BaseLayout"
import ShareBox from "@/components/share-tools/ShareBox"
import { useDbStore } from "@/stores/dbStore"
import { useSettingsStore } from "@/stores/settingsStore"
import { onMounted } from 'vue'
export default {
  setup() {
    const dbStore = useDbStore();
    const dbSettings = useSettingsStore();
    dbStore.setHomeIntro();
    dbStore.setHomeServices();
    dbStore.setHomeServices2();
    dbStore.setAllServices();
    dbStore.setObrasIntro();
    dbStore.setGallery();
    onMounted(() => {
      dbSettings.setSiteUrl(window.location.href);
    });
  },
  components: {
    BaseLayout,
    ShareBox
  },
};
</script>
<style lang="scss">
@import "@/assets/styles.scss";
</style>
