<template>
  <footer>
    <div class="footer-wrap container container_md full-height full-width flex space-evenly">
      <div class="full-height flex x-center">
        <div class="height-100p flex f-col space-evenly">
          <img class="zidar-logo-footer-mobile" src="@/assets/zidarspace-logo.png" width="100" />
          <h3>CONTACTO</h3>
          <div>
            <h4 class="margin-bottom-10"><span>Whatsapp:</span> <WspCall /></h4>
            <h4><span>Email:</span> <a class="white underline-none"
            href="mailto=contacto@zidarspace.com" 
            title="contacto@zidarspace.com"
            target="_blank">contacto@zidarspace.com</a></h4>
          </div>
          <img class="zidar-logo-footer-desk" src="@/assets/zidarspace-logo.png" width="60" />
        </div>
      </div>
      <div class="height-100p flex x-center">
        <div class="height-100p flex f-col space-evenly">
          <h3 class="flex x-center">SEGUINOS EN REDES</h3>
          <div class="full-width space-evenly flex x-center">
            <span v-if="false"
              class="iconify"
              data-icon="bx:bxl-linkedin-square"
              data-width="30"
              data-height="30"
            ></span>
            <span v-if="false"
                class="iconify"
                data-icon="ci:facebook"
                data-width="30"
                data-height="30"
              ></span>
              <a href="https://instagram.com/zidarspace" class="white underline-none" target="_blank" title="Seguinos en Instagram">
                <span 
                  class="iconify"
                  data-icon="mdi:instagram"
                  data-width="24"
                  data-height="24"
                ></span>
            </a>
          </div>
          <p class="flex x-center copy copy_desk">&copy;2021 by <a class="underline-none white inline-block margin-left-5" href="https://www.fiouxlabs.com">fiouxlabs.com</a></p>
        </div>
      </div>
      <div class="height-100p flex x-center">
        <div class="height-100p flex f-col space-evenly">
          <h3>HORARIO DE TRABAJO</h3>
          <div>
            <h4 class="margin-bottom-10">Lunes a Viernes: 8am - 8pm</h4>
            <h4 class="margin-bottom-40">Sábados: 9am - 1pm</h4>
            <h4 class="margin-bottom-10">Montevideo - Uruguay</h4>
            <p class="flex x-center copy copy_mobile full-width txt-center">&copy;2021 by <a class="underline-none white inline-block margin-left-5" href="https://www.fiouxlabs.com">fiouxlabs.com</a></p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import WspCall from '@/components/share-tools/WspCall'
export default {
  name: "BaseFooter",
  setup() {},
  components:{
    WspCall
  }
};
</script>