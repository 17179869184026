<template>
  <header class="header full-width height-200 flex x-center items-center">
    <NavBar />
  </header>
</template>
<script>
import NavBar from "@/components/share-tools/NavBar";
export default {
  name: "BaseHeader",
  setup() {},
  components: {
    NavBar,
  },
};
</script>