<template>
  <div class="container container_md flex x-center items-center space-between">
    <router-link to="/">
      <img class="logo-img" src="@/assets/zidarspace-logo.png" width="150" />
    </router-link>
    <ul class="ulclass flex items-center">
          <li>
            <router-link
              class="navbartext"
              :class="{ blue: $route.path == '/' }"
              to="/"
              >Inicio</router-link
            >
          </li>
          <li>
            <router-link
              class="navbartext"
              :class="{ blue: $route.path == '/servicios' }"
              to="servicios"
              >Servicios</router-link
            >
          </li>
          <li>
            <router-link
              class="navbartext"
              :class="{ blue: $route.path == '/obras' }"
              to="obras"
              >Obras</router-link
            >
          </li>
          <li>
            <router-link
              class="navbartext"
              :class="{ blue: $route.path == '/contacto' }"
              to="contacto"
              >Contacto</router-link
            >
          </li>
    </ul>
    <button href="javascript:void(0)"
      class="burger-icon button button_icon absolute zindex-900"
      :class="{'blue':menuIsShow}"
      @click.prevent="showMenu"
      >
       <span class="material-icons" >{{menuIcon}}</span>
    </button>
      <nav  class="menu fixed top-0 left-0 full-width vh-100 flex row wrap items-center x-center zindex-800"
      :class="{'menu_on': menuIsShow}">
          <ul class="full-width height-50p flex f-col space-evenly items-center">
            <li>
              <router-link
                class="navbartext"
                to="/"
                @click="showMenu"
                >Inicio</router-link
              >
            </li>
            <li>
              <router-link
                class="navbartext"
                to="servicios"
                @click="showMenu"
                >Servicios</router-link
              >
            </li>
            <li>
              <router-link
                class="navbartext"
                to="obras"
                @click="showMenu"
                >Obras</router-link
              >
            </li>
            <li>
              <router-link
                class="navbartext"
                to="contacto"
                @click="showMenu"
                >Contacto</router-link
              >
            </li>
          </ul>
      </nav>
  </div>
</template>
<script>
import { useDbStore } from "@/stores/dbStore";
import { reactive, toRefs, computed } from "vue";
export default {
  setup() {
    const dbStore = useDbStore();
    const dataNavBar = reactive({
      burgerclick: false,
      menuIsShow: false,
      menuIcon:computed(()=>{
        return dataNavBar.menuIsShow ? 'close' : 'menu';
      })
    });
    function showMenu() {
      dataNavBar.menuIsShow = !dataNavBar.menuIsShow;
    }
    return {
      ...toRefs(dataNavBar),
      dbStore,
      showMenu
    };
  },
};
</script>
