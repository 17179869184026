<template>
  <main role="main">
    <router-view />
  </main>
</template>
<script>
export default {
  name: "BaseMain",
  setup() {},
};
</script>